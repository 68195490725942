/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import C17 from "./calendarimg/may20.jpg"
import C18 from "./calendarimg/may18.jpg"
import C19 from "./calendarimg/25.jpg"
import IRBG from "../../../components/IRBG"

import CalendarNav from "../../../components/CalendarNav"
import HomeLogo from "../../../components/HomeLogo"

import IRNav from "../../../components/IRNav"

export default class CalendarPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Calendar - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Corporate Calendar
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <CalendarNav />
          <div className="column is-offset-2">
            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C17} />
              </div>
              <div className="column">
                <h1 className="subtitle">Final Dividend</h1>
                <p>
                  Date Announced : 20/05/2016
                  <br />
                  EX-date : 13/06/2016
                  <br />
                  Entitlement date : 15/06/2016
                  <br />
                  Entitlement time : 05:00:00 PM
                  <br />
                  Entitlement subject : Final Dividend
                  <br />
                  Entitlement description : Final Tax Exempt (Single-Tier)
                  dividend of 1 sen per RM0.25 ordinary share in respect of the
                  year ended 31 December 2015.
                  <br />
                  Financial Year End : 31/12/2015
                  <br />
                  Payment date : 30/06/2016
                </p>
              </div>
            </div>
          </div>
          <div className="column is-offset-2" id="calendar-line">
            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C18} />
              </div>
              <div className="column">
                <h1 className="subtitle">
                  22nd Annual General Meeting ("AGM") of E.A. Technique (M)
                  Berhad
                </h1>
                <p>
                  <br />
                  Date of Meeting : 18 May 2016
                  <br />
                  Time : 12:00 PM
                  <br />
                  Venue : Mahkota I Ballroom, Level BR, Hotel Istana Kuala
                  Lumpur City Centre, 73 Jalan Raja Chulan, 50200 Kuala Lumpur,
                  Malaysia
                  <br />
                  Date of General Meeting Record of Depositors : 11 May 2016
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-1">
                <img src={C19} />
              </div>
              <div className="column">
                <h1 className="subtitle">Circular/Notice to Shareholders</h1>
                <p>
                  CIRCULAR TO SHAREHOLDERS IN RELATION TO THE PROPOSED RENEWAL
                  OF SHAREHOLDERS' MANDATE TO ENABLE E.A. TECHNIQUE (M) BERHAD
                  TO PURCHASE UP TO TEN PERCENT (10%) OF ITS ISSUED AND PAID-UP
                  SHARE CAPITAL
                </p>
              </div>
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
